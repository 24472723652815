// Definitions:
//  - histHidden: Will hide the selected Employment Event in the employment history
//  - isPermanent: Will remove delete button when editing events
//  - isHidden: Hides the selected Employment Event from being in the options dropdown when creating a new employment event
//  - isDeemedCheck: Is the event that triggers the deemed calculations task
//  - ignoreForEndTs: When assigning end timestamps to the employment events (see assignEndTs()), adding this attribute
//                  will skip over the next employment event if its status is equal to a value in ignoreForEndTs
//                  (ex: when a member has status of Progressive Return (apr), their previous deemed status (on leave,
//                  maternity or self) is not supposed to end until another status happens other than Progressive Return)
//  - next: The next action that should happen automatically when the selected Employment Event is triggered
//  - slfContribAllowed: The employment event is eligible for self contribution calculations
//  - status: The corresponding Employment Status of the selected Employment Event
//  - expiration: Expirations defined per type of leave
//  - onUpdate (ASYNC): triggers when the event values are updated. 
//  - useInMembersUploadStatusUpdate: use this event code in the Members Upload to update the employment status
//  - isReplacable: if event in group and replacable, it means the code can be switched to another event of same group
//  - isSwitchEvent: if event is a TSW event (switched-out event)
//  - employmentRelationTerminatedIn60Days: the event has a 60 days expiration date for the employment relation

import moment from "moment";
import { EmploymentService } from "../../services";

const triggerPendingTrm = (params) => {
    const lastEvent = params.event;
    const pp = params.employment.participation;
    let hasActive = false;
    for (let employment of params.employment.participation.employments.all.filter(x=>x.employer.keyValue !== params.employment.employer.keyValue)) {
        if (employment.status.isActive() || employment.status.isOnLeave()) hasActive = true;
    }
    if (!hasActive) {
        if(params.employment.person.isOverAge(60)){
            pp.addEvent({code: 'penPnr', ets: lastEvent.ets, mts: lastEvent.mts + 100 }, params);
        }else pp.addEvent({code: 'penTrm', ets: lastEvent.ets, mts: lastEvent.mts + 100 }, params);
    } 
}

const onUpdateHrd = async ({event, employment }) => {
    const employments = await EmploymentService.getParticipationEmployments(employment.participation.keyValue);
    let oldestEvent = event.ets;
    for ( const otherEmployment of employments.filter((x) => x.keyValue !== employment.keyValue) ) {
        const hiredTs = otherEmployment.getHiredEvent()?.ets;
        if (hiredTs < oldestEvent) oldestEvent = hiredTs;
    }
    const potentialEvent = employment.participation.getPotentialEvent();
    const nrolEvent = employment.participation.getActiveStatusEvent();
    //pot shouldnt be higher than nrol event
    if (!nrolEvent || (nrolEvent && nrolEvent.ets > oldestEvent)) potentialEvent.ets = oldestEvent;
}

export const employmentEventConfigs = {
    default: {
        isPermanent: false, 
        priority: 0,
        /**
         * On add event, add the remittance pointer (key: `employerId_period`) if provided
         * @param {{event: EmploymentEvent; openRemittance: Remittance | undefined;}} param0 
         * @returns {EmploymentEvent}
         */
        onAdd: ({event, openRemittance}) =>  {
            if (openRemittance) {
                event.pointers.pushNew({
                    registryKey: 'remittances', 
                    name: 'remittance', 
                    // instanceKey of remittance pointer is `employerId_period`, see usage in RefEvent.EVENT_SOURCE.FILE and Remittance.isRemittanceEvent and ParticipationConfig.withPointersOnAdd
                    instanceKey: openRemittance.keyValue,
                    instance: openRemittance
                });
            }
            return event;
        }
    },
    apr: { status: 'apr', isProgressiveReturn: true },
    asc: { isHidden: () => true, selfContribAccepted: true, priority: 1 },
    dsc: { isHidden: () => true, selfContribDeclined: true, priority: 1 },
    esc: { isHidden: () => true, selfContribDeclined: true, priority: 1 },
    hrd: {
        status: 'act',
        singleEvent: true,
        isPermanent: true, 
        isHiredEvent: true,
        isHidden: () => true,
        onUpdate: onUpdateHrd,
        isReplacable: true,
        singleEventInGroup: true,
        hiddenGroup: 'HRD',
        priority: 0,
    },
    hrdRem: {
        status: 'act',
        singleEvent: true,
        isPermanent: true, 
        isHiredEvent: true,
        isHidden: () => true,
        onUpdate: onUpdateHrd,
        isReplacable: true,
        singleEventInGroup: true,
        hiddenGroup: 'HRD',
        priority: 0,
    },
    lad: { 
        status: 'lad', 
        slfContribAllowed: true,
        ignoreForEndTs: ['apr'],
        expiration: {fed: 63, que: 65},
        useInMembersUploadStatusUpdate: true,
    },
    lcc: { 
        status: 'lcc', 
        slfContribAllowed: true,
        ignoreForEndTs: ['apr'],
        isHidden: () => true,
        expiration: 52,
    },
    lco: { 
        status: 'lco', 
        slfContribAllowed: true,
        ignoreForEndTs: ['apr'],
        expiration: { fed: 52, que: 52},
        useInMembersUploadStatusUpdate: true,
    },
    led: { 
        status: 'led',
        expiration: 52,
        useInMembersUploadStatusUpdate: true,
    },
    leo: { status: 'leo', isHidden: () => true, expiration: 52 },
    loa: { 
        status: 'loa',
        ignoreForEndTs: ['apr'],
        isHidden: () => true,
        expiration: 52,
    },
    lpl: {
        status: 'lpl',
        ignoreForEndTs: ['apr'],
        expiration: {que: 65, fed: 63},
        useInMembersUploadStatusUpdate: true,
    },
    lpp: { 
        status: 'lpp', 
        isHidden: () => true,
        ignoreForEndTs: ['apr'],
        expiration: {que: 65, fed: 63},
    },
    lpy: { 
        status: 'lpy',
        ignoreForEndTs: ['apr'],
        expiration: {que : 5}
    },
    lso: { 
        status: 'lso',
        expiration: 52,
        useInMembersUploadStatusUpdate: true,
    },
    lst: { 
        status: 'lst', 
        slfContribAllowed: true,
        ignoreForEndTs: ['apr'],
        useInMembersUploadStatusUpdate: true,
    },
    lsw: { 
        status: 'lsw' ,
        expiration: 52,
        useInMembersUploadStatusUpdate: true,
    },
    ltd: { 
        status: 'ltd',
        ignoreForEndTs: ['apr'],
        useInMembersUploadStatusUpdate: true,
    },
    lun: { status: 'lun', expiration: 52, isUnspecifiedLeave: true },
    mat: { 
        status: 'mat',
        ignoreForEndTs: ['apr'],
        useInMembersUploadStatusUpdate: true,
    },
    mer: { isHidden: () => true },
    ppmat: { 
        isHidden: () => true,
        status: 'mat' 
    },
    rim: {
        next: (params) => {
            const pp = params.employment.participation;
            pp.addEvent({code: 'potRmb'});
        },
        automatedEffectiveDate: moment(),
    },
    rtw: { 
        status: 'act',
        isReturnToWork: true,
    },
    stsConfAct : {
        isHidden: () => true,
    },
    stsConfDen: {
        isHidden: () => true,
        histHidden: true,
    },
    stsConfInf60: { isHidden: () => true },
    stsConfSup60: { isHidden: () => true },
    susPay: {
        status: 'lsp',
        useInMembersUploadStatusUpdate: true,
    },
    tde: {
        status: 'tde',
        next : (params) => {
            const pp = params.employment.participation;
            pp.addEvent({code: 'penDea'});
        },
        useInMembersUploadStatusUpdate: true,
    },
    tfq: { 
        status: 'tfq',
        next: triggerPendingTrm,
        useInMembersUploadStatusUpdate: true,
        employmentRelationTerminatedIn60Days: true,
    },
    tex: { 
        status: 'tex',
        next: triggerPendingTrm,
        employmentRelationTerminatedIn60Days: true,
    },
    tnf: { status: 'tcl', manualClose: true},
    tnc: { status: 'tcl', manualClose: true},
    tlo: { 
        status: 'tlo',
        expiration: 52,
        useInMembersUploadStatusUpdate: true,
    },
    trd: {
        status: 'trd',
        next: (params) => {
            const pp = params.employment.participation;
            pp.addEvent({code: 'penPnr'});
        },
        useInMembersUploadStatusUpdate: true,
    },
    tro: { isMultipleEmployer: true, status: 'trf',},
    tsw: { isMultipleEmployer: true, status: 'trf', isSwitchEvent: true},
    swi: { isMultipleEmployer: true },
    mer: { isMultipleEmployer: true},
    lgcyTri: { isMultipleEmployer: true },
    ynp: { status: 'act' },
    lms: {
        status: 'lms',
        expiration: 52,
    },
    tclCan: {status: 'tcl', manualClose: true},
    cDmd: {
        isHidden: () => true,
        histHidden: true,
        isDeemedCheck: true,
    },
    memS: {
        memSusEmailSent: true,
    },
    actAutDed: {
        deductionSent: true,
    },
    psd: {
        payrollStartDate: true,
    },
    fqToLayOff: {
        histHidden: true,
        isHidden: () => true,
        isFiredQuitToLayOff: true,
    },
    ltdIns: {
        insuranceLetterSent: true,
        isHidden: () => true,
    }
}

